import React from "react"
// import Img from "gatsby-image"
import style from "./styles.module.css"
import { Container } from "reactstrap"


const SolutionsInfoSection = ({ heading, title, description, title2, description2 }) => {
    return (
        <Container
            fluid
            // className=""
            style={{
                background: `rgb(243, 243, 243)`,
                paddingTop: `2rem`,
                paddingBottom: `2rem`,
            }}
        >
            <h1 className="mx-auto mb-4 heading1 p-3 text-center">
                <span>{heading}</span>
            </h1>

            <div className={style.solutionsInfoContainer}>
                <div className={style.solutionsInfoContent}>
                    <h2>{title}</h2>
                    <p>
                        {description}
                    </p>
                </div>
                <div className={style.solutionsInfoContent}>
                    <div className={style.solutionsInfoContent}>
                        <h2>{title2}</h2>
                        <p>
                            {description2}
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default SolutionsInfoSection