import React from "react"
import Layout from "../../../Components/pageComponents/contentContainer"
import { Row, Col } from "reactstrap"


import Image from "../../../Components/pageComponents/carouselCard"
import DoubleDescription from "../../../Components/pageComponents/doubleDescription"
import Carousel from "../../../Components/pageComponents/carousel"


export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/computing/servers-and-storages/Servers & Storages.png" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        tower_server: file(relativePath: { eq: "products/computing/servers-and-storages/Tower Servers Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rack_server: file(relativePath: { eq: "products/computing/servers-and-storages/Rack Server Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        blade_server: file(relativePath: { eq: "products/computing/servers-and-storages/Blade Server Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        network_storage: file(relativePath: { eq: "products/computing/servers-and-storages/Network Attached Storage Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        area_storage: file(relativePath: { eq: "products/computing/servers-and-storages/Storage Area Network.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        disk_storage: file(relativePath: { eq: "products/computing/servers-and-storages/Just a bunch of disk Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        local_storage: file(relativePath: { eq: "products/computing/servers-and-storages/Locally Attached Storage Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default ({ data }) => {
    return (
        <Layout title="Servers & Storages" image={data.banner.childImageSharp.fluid}>
            <DoubleDescription heading="Products We Offer" title="Servers" title2="Storages" description="Server is a dedicated computer or program that manages network resources and provides various services among its clients." description2="Storage is a purpose built server that is used to store, access, secure and manage small to large amounts of digital files and services over a shared network or via the internet." />
            <div class="px-3">
                <Row className="bg-blue text-white ff-primary">
                    <Col md="12">
                        <h1 class="text-center p-5">
                            Types of Servers
                        </h1>
                    </Col>
                </Row>
                <Carousel>
                    <Image img={data.tower_server.childImageSharp.fluid}>
                        Tower Server
                    </Image>
                    <Image img={data.rack_server.childImageSharp.fluid}>
                        Rack Server
                    </Image>
                    <Image img={data.blade_server.childImageSharp.fluid}>
                        Blade Server
                    </Image>
                </Carousel>
                <Row className="bg-blue text-white ff-primary mt-4">
                    <Col md="12">
                        <h1 class="text-center px-5 my-5">
                            Types of Storages
                        </h1>
                    </Col>
                </Row>
                <Carousel>
                    <Image img={data.network_storage.childImageSharp.fluid}>
                        Network Attached Storage (NAS)
                    </Image>
                    <Image img={data.area_storage.childImageSharp.fluid}>
                        Storage Area Network (SAN)
                    </Image>
                    <Image img={data.disk_storage.childImageSharp.fluid}>
                        Just a Bunch of Disk (JBOD)
                    </Image>
                    <Image img={data.local_storage.childImageSharp.fluid}>
                        Locally Attached Storage
                    </Image>
                </Carousel>
            </div>
        </Layout>
    )
}